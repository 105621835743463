import { ArrowRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Grid,
  GridItem,
 
  Text,

} from "@chakra-ui/react";


function OngoingProjects() {
  return (
    <Box>
          <Box >
        <Box mt="50px" textAlign={"left"} ml='30px'>
          <Text color={"red.400"} as="b" fontSize="30px">
            Coal Mining Projects
          </Text>
        </Box>
        <Grid templateColumns="repeat(1, 1fr)" gap={7} mt="50px">
          <GridItem
            w="100%"
            h={["72px","10","10"]}
            textAlign={["justify","justify","justify"]}
            bg="orange.200"
            width={"90%"}
            margin="auto"
            display={"flex"}
            justifyContent={"left"}
     alignItems={"center"}
          >
            <ArrowRightIcon ml={"20px"} />{" "}
            <span style={{ marginLeft: "40px" }}>
              {" "}
              Hiring of HEMM for removal of Overburden, Coal Extraction and transportation at ROCP, Bastacolla  Area.

            </span>
          </GridItem>
          <GridItem
            w="100%"
            h={["52px","10","10"]}
            textAlign={["justify","justify","justify"]}
            bg="orange.200"
            width={"90%"}
            margin="auto"
            display={"flex"}
            justifyContent={"left"}
     alignItems={"center"}
          >
            <ArrowRightIcon ml={"20px"} />{" "}
            <span style={{ marginLeft: "40px" }}>
              {" "}
              Mine Developer Cum Operator for Tadicheral-I Coal Mine of TSGENCO

            </span>
          </GridItem>
          <GridItem
            w="100%"
            h={["52px","10","10"]}
            textAlign={["justify","justify","justify"]}
            bg="orange.200"
            width={"90%"}
            margin="auto"
            display={"flex"}
            justifyContent={"left"}
     alignItems={"center"}
          >
            <ArrowRightIcon ml={"20px"} />{" "}
            <span style={{ marginLeft: "40px" }}>
              {" "}
              Mine Developer Cum Operator of Kotare Basantpur Coal Block

            </span>
          </GridItem>
          <GridItem
            w="100%"
            h={["52px","10","10"]}
            textAlign={["justify","justify","justify"]}
            bg="orange.200"
            width={"90%"}
            margin="auto"
            display={"flex"}
            justifyContent={"left"}
     alignItems={"center"}
    
          >
            <ArrowRightIcon ml={"20px"} />{" "}
            <span style={{ marginLeft: "40px" }}>
              {" "}
              Mine Developer Cum Operator of Chatti Bariatu Coal Block

            </span>
          </GridItem>
          <GridItem
            w="100%"
            h={["52px","10","10"]}
            textAlign={["justify","justify","justify"]}
            bg="orange.200"
            width={"90%"}
            margin="auto"
            display={"flex"}
            justifyContent={"left"}
     alignItems={"center"}
          >
            <ArrowRightIcon ml={"20px"} />{" "}
            <span style={{ marginLeft: "40px" }}>
              {" "}
              Mine Developer Cum Operator for Tokisud North Coal Block

            </span>
          </GridItem>
          <GridItem
            w="100%"
            h={["52px","10","10"]}
            textAlign={["justify","justify","justify"]}
            bg="orange.200"
            width={"90%"}
            margin="auto"
            display={"flex"}
            justifyContent={"left"}
     alignItems={"center"}
          >
            <ArrowRightIcon ml={"20px"} />{" "}
            <span style={{ marginLeft: "40px" }}>
              {" "}
              Mine Developer and Operator for development and Operation of Utkal D &E Coal Mine

            </span>
          </GridItem>
          <GridItem
            w="100%"
            h={["92px","10","10"]}
            textAlign={["justify","justify","justify"]}
            bg="orange.200"
            width={"90%"}
            margin="auto"
            display={"flex"}
            justifyContent={"left"}
     alignItems={"center"}
          >
            <ArrowRightIcon ml={"20px"} />{" "}
            <span style={{ marginLeft: "40px" }}>
              {" "}
              Exploration, Development & Operations of 25 Ha. Iron Ore bearing area in H. Siddapuram Village, D. Hirelal Mandal, Ananthapuram District, A.P.

            </span>
          </GridItem>

        </Grid>
      </Box>

      <Box >
        <Box mt="50px" textAlign={"left"} ml='30px'>
          <Text color={"red.400"} as="b" fontSize="30px">
          Irrigation Projects
          </Text>
        </Box>
        <Grid templateColumns="repeat(1, 1fr)" gap={7} mt="50px">
          
          <GridItem
            w="100%"
            h={["52px","10","10"]}
            textAlign={["justify","justify","justify"]}
            bg="orange.200"
            width={"90%"}
            margin="auto"
            display={"flex"}
            justifyContent={"left"}
     alignItems={"center"}
          >
            <ArrowRightIcon ml={"20px"} />{" "}
            <span style={{ marginLeft: "40px",fontSize:"15px" }}>
              {" "}
              Package -15 Gravity Canal reach 1 nera Mulkanpalli in Nalgonda District, Telangana

            </span>
          </GridItem>

        </Grid>
      </Box>

      <Box>
        <Box mt="50px" textAlign={"left"} ml='30px'>
          <Text color={"red.400"} as="b" fontSize="30px">
            Roads & Infrastructure Projects
          </Text>
        </Box>
        <Grid templateColumns="repeat(1, 1fr)" gap={7} mt="50px">
          
          <GridItem
            w="100%"
            h={["52px","10","10"]}
            textAlign={["justify","justify","justify"]}
            bg="orange.200"
            width={"90%"}
            margin="auto"
            display={"flex"}
            justifyContent={"left"}
     alignItems={"center"}
          >
            <ArrowRightIcon ml={"20px"} />{" "}
            <span style={{ marginLeft: "40px" }}>
              {" "}
              South Central Railway-Guntur-Gunthakal Doubling Misc. Civil Works

            </span>
          </GridItem>
          <GridItem
            w="100%"
            h={["80px","10","10"]}
            textAlign={["justify","justify","justify"]}
            bg="orange.200"
            margin={"auto"}
            width={"90%"}
            display={"flex"}

            justifyContent={"left"}
     alignItems={"center"}
          >
            <ArrowRightIcon ml={"20px"} />{" "}
            <span style={{ marginLeft: "40px" }}>
              {" "}
              South Central Railway-Gunthakal Division-Earthwork & Bridges connecting Jakkalcheruvu and Dharmavaram side

            </span>
          </GridItem>
          <GridItem
            w="100%"
            h={["80px","10","10"]}
            textAlign={["justify","justify","justify"]}
            bg="orange.200"
            width={"90%"}
            margin="auto"
            display={"flex"}
            justifyContent={"left"}
     alignItems={"center"}
          >
            <ArrowRightIcon ml={"20px"} />{" "}
            <span style={{ marginLeft: "40px" }}>
              {" "}
              East Coast Railway-Construction of Limited Height Subways on Main Line under the jurisdiction of AEN/Vizianagarm

            </span>
          </GridItem>
          <GridItem
            w="100%"
            h={["52px","10","10"]}
            textAlign={["justify","justify","justify"]}
            bg="orange.200"
            width={"90%"}
            margin="auto"
            display={"flex"}
            justifyContent={"left"}
     alignItems={"center"}
          >
            <ArrowRightIcon ml={"20px"} />{" "}
            <span style={{ marginLeft: "40px" }}>
              {" "}
              Construction of Limited Height Subways Main Line, Srikakulam, AP

            </span>
          </GridItem>
          <GridItem
            w="100%"
            h={["52px","10","10"]}
            textAlign={["justify","justify","justify"]}
            bg="orange.200"
            width={"90%"}
            margin="auto"
            display={"flex"}
            justifyContent={"left"}
     alignItems={"center"}
          >
            <ArrowRightIcon ml={"20px"} />{" "}
            <span style={{ marginLeft: "40px" }}>
              {" "}
              Development of Infrastructure works at Pinnapuram, Kurnool

            </span>
          </GridItem>

        </Grid>
      </Box>

    </Box>

  );
}

export default OngoingProjects;
