import React, { useEffect, useState } from 'react'

import {  Image, Box, Button, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import image2 from "../Images/Slider/Picture2.jpeg"
import image3 from "../Images/Slider/Picture1.jpg"
import image4 from "../Images/Slider/Picture4.jpeg"
import image5 from "../Images/Slider/Picture5.jpeg"
import { ArrowForwardIcon } from '@chakra-ui/icons'

function Header() {



  const [selectedImage, setSelectedImage] = useState(0)
  const [allImages, setAllImages] = useState([image5, image2, image3, image4])

  useEffect(() => {
    setInterval(() => {
      setSelectedImage(selectedImage => selectedImage < 3 ? selectedImage + 1 : 0)
    }, 7000)
  }, [])


  return (
    <Box className='mainDiv' w={["100%", "100%", "100%"]} >
      <Image width={["100%","100%","100%"]}  height={["30vh","60vh","90vh"]} src={allImages[selectedImage]} margin={"auto"}  /> <br />

      <Box top={["5%", "12%", "20%"]} display={["none","block","block"]} position="absolute" left={["20px","20px","60px"]} textAlign={"justify"} lineHeight={["30px", "40px", "50px"]}  w={["90%", "90%", "90%"]}>
        <Text fontSize={["1xl", "20px", "30px"]} as="b" color={"white"}> WELCOME TO AMR GROUP</Text>
        <Text fontFamily={"Arial (sans-serif)"} width={["75%", "50%", "40%"]} fontSize={["1xl", "1   6px", "25px"]} color="white">Empowering progress through responsible excavation of the earth's riches."</Text>
        <Link to="/mission">
          <Button w={["60px", "90px", "120px"]} bg="orange" height={["30px"]} fontSize={["10px","15px","20px"]} borderRadius="10%" >See More <span ml="10px"><ArrowForwardIcon /></span></Button>
        </Link>
      </Box>
    </Box>
  )
}

export default Header
