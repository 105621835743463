import React, { useEffect } from "react";
import {
  Link,
  Divider,
  HStack,
  Tag,
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
 
  Image,
 
} from "@chakra-ui/react";

import { } from "@chakra-ui/react";
import I1 from "../Images/Mining/Image1.jpg";
import I2 from "../Images/Mining/Image2.JPG";
const BlogTags = (props) => {
  
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={"md"} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

export const BlogAuthor = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const MiningPage = () => {
  return (

    <Container maxW={"9xl"} p={["1", "4", "12"]} 
    >
      <Heading color="red.400"
        as="h1">Our Mining Service</Heading>
     


      <Container maxW={'7xl'} p="8" mt={["-15px",]}>
        <Box

          marginTop={{ base: '2', sm: '5' }}
          display="flex"
          flexDirection={["column", "column", "row"]}
          justifyContent="space-between">
          <Box
            width={"100%"}

            display="flex"
            flex="1"
            marginRight="56px"
            position="relative"
            alignItems="center">
            <Box
              position={"relative"}
              top={"20px"}
              height={"300px"}
              rounded={"2xl"}
              boxShadow={"2xl"}
              width={"100%"}
              overflow={"hidden"}
            >
            
              <Image
                alt={"Hero Image"}
                fit={"cover"}
                align={"center"}
                w={"100%"}
                h={"100%"}
                src={I1}
              />
            </Box>
            <Box zIndex="1" width="100%" position="absolute" height="100%"

            >
            
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: '3', sm: '9' }}>
            <Heading marginTop="1" color={'red.400'}>
              <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                Mining
              </Link>
            </Heading>
            <Text
              as="p"
              marginTop="5"
              fontSize={"lg"}
              color={"gray.500"}
              textAlign={'justify'}>
             The mining division of AMR INDIA LIMITED has its core operations in
              mineral rich belt of INDIA. Right from planning, designing,
              execution and till mine closure in the most possible environmentally
              sustainable manner. The company has capability of dealing with most
              of core minerals including Coal, Limestone, Lignite, Manganese, Iron
              ore & Bauxite.
            </Text>
          </Box>

        </Box>
       
        <Divider marginTop="5" />

      </Container>

      {/* *************************************************************************************************************** */}

      <Container maxW={"7xl"} mt={["0px", "-90px", "30px"]}>

        <Stack
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 5, md: 8 }}
          direction={{ base: "column", md: "row" }}
        >
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>

            <Text fontSize={'lg'} color={"gray.500"} textAlign="justify">
            AMR India Limited has annual mining capacity of over 65 million cubic
              meters. Mining operations are mechanized adapting to the
              state-of-the-art technology and equipment. Flexibility in operations
              enables the division to maximize the utilization of resources as well
              as to cater to the needs of diverse customer segments. AMR India
              Limited’s roadmap includes exploring further mine acquisitions and
              leveraging upon both Greenfield and brownfield opportunities.
              Furthermore, to capitalize on the wealth of experience and expertise,
              garnered in the mining sector. Plans to venture into Coal
              gasification, Coal bed methane are on the anvil.
            </Text>

          </Stack>
          <Flex
            flex={1}
            justify={"center"}
            align={"center"}
            position={"relative"}
            w={"full"}
          >

            <Box
              position={"relative"}
              height={"300px"}
              rounded={"2xl"}
              boxShadow={"2xl"}
              width={"full"}
              overflow={"hidden"}
            >
            
              <Image
                alt={"Hero Image"}
                fit={"cover"}
                align={"center"}
                w={"100%"}
                h={"100%"}
                src={I2}
              />
            </Box>
          </Flex>
        </Stack>
      </Container>

    </Container>
  );
};



export default MiningPage;
