import { Box, Flex, HStack, Image, Stack, Text ,Link} from "@chakra-ui/react";
import Logo from "../Images/Navbar/Navbar.jpg";
import { ImOffice } from 'react-icons/im';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';

function Footer() {
  return (
    < Box>
    

<Box width={"100%"}  height={["200px","150px","150px"]}  bg={"black"}  display={["block","flex","flex"]} justifyContent={"center"} alignItems={"center"} >
        {/* <Box width={["50%","20%","25%"]} height={["40px","50px","100px"]} margin={"auto"} >
          <Image width={["70%","100%","70%"]} height={["60px","90px","100px"]} src={Logo} margin={"auto"}/>
        </Box> */}


        <Box width={["90%","42%","25%"]} display={"flex"} gap={["3","5","7"]} margin={"auto"} mt={["43px","40px","30px"]} >
           <Box color={"white"} mt={"2"}><ImOffice/></Box>
           <Box>
          <Text color={"white"} width={"100%"} textAlign={"justify"} lineHeight={"9"}>
            Saikrupa, D.No:8-3-833, Plot: 37 &38, Phase-1, Kamalapuri Colony, Hyderabad, Telangana 500073
            </Text>
            </Box>
        </Box>

        <Box width={["100%","30%","25%"]}    gap={["5px","8px","10px"]} mt={["20px","20px","20px"]} >
          <Box display={"flex"} gap={"7"}  ml={["20px","40px","50px"]} >
           <Box color={"white"} mt={"3"}><BsFillTelephoneFill/></Box>
           <Box >
          <Text color={"white"} width={"100%"} textAlign={"justify"} lineHeight={"9"} >
          040 4757 0009

</Text>
            </Box>
        </Box>



        <Box display={"flex"} gap={"7"} ml={["20px","40px","50px"]} mt={"10px"}>
           <Box color={"white"} mt={"3"}><MdEmail/></Box>
           <Box>
          <Text color={"white"} width={"100%"} textAlign={"justify"} lineHeight={"9"}>
          info@amrgroup.in            </Text>
            </Box>
        </Box>
        </Box>
        
        </Box>
      

      <Box bg={"grey"}>
        <Text color="white">@copyright 2023  All right reserved to AMR Group</Text>
      </Box>
    </Box>
  );
}

export default Footer;
