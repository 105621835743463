


import React, { useState } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Button,
  ButtonGroup,
  Text,
  Image,
} from '@chakra-ui/react';
import c3 from "../Images/Gallery/Tadicherla/c3.jpg";
import c4 from "../Images/Gallery/Tadicherla/c4.JPG";
import c5 from "../Images/Gallery/Tadicherla/c5.JPG";
import c6 from "../Images/Gallery/Tadicherla/c6.JPG";
import c7 from "../Images/Gallery/Tadicherla/c7.JPG";
import c13 from "../Images/Gallery/Tadicherla/c7.jpeg";


import a2 from "../Images/Gallery/Package15/a2.JPG";
import a3 from "../Images/Gallery/Package15/a3.JPG";
import a1 from "../Images/Gallery/Package15/a1.JPG";
import a4 from "../Images/Gallery/Package15/a4.JPG";
import a5 from "../Images/Gallery/Package15/a5.JPG";
import a6 from "../Images/Gallery/Package15/a6.JPG";
import a7 from "../Images/Gallery/Package15/a7.JPG";
import a8 from "../Images/Gallery/Package15/a8.JPG";




import b2 from "../Images/Gallery/KondaPochama/b2.JPG";
import b3 from "../Images/Gallery/KondaPochama/b3.JPG";
import b1 from "../Images/Gallery/KondaPochama/b1.JPG";
import b4 from "../Images/Gallery/KondaPochama/b4.JPG";
import b5 from "../Images/Gallery/KondaPochama/b5.JPG";
import b6 from "../Images/Gallery/KondaPochama/b6.JPG";
import b7 from "../Images/Gallery/KondaPochama/b7.JPG";
import b8 from "../Images/Gallery/KondaPochama/b8.JPG";



import d2 from "../Images/Construction/d1.jpg"
import d3 from "../Images/Construction/d2.JPG"
import d1 from "../Images/Construction/d3.jpg"
import d4 from "../Images/Construction/d4.jpg"
import d5 from "../Images/Construction/d5.jpg"
import d6 from "../Images/Construction/d6.jpg"
import d7 from "../Images/Construction/d7.JPG"
import d8 from "../Images/Construction/d8.JPG"
import d9 from "../Images/Construction/d9.JPG"
import d10 from "../Images/Construction/d10.JPG"
import d11 from "../Images/Construction/d11.jpg"
import d12 from "../Images/Construction/d12.jpg"

const Gallery = () => {
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [galleryItems, setGalleryItems] = useState([
    { id: 3, category: 'Mining', imageUrl: c3 },
    { id: 4, category: 'Mining', imageUrl: c4 },
    { id: 5, category: 'Mining', imageUrl: c5 },
    { id: 6, category: 'Mining', imageUrl: c6 },
    { id: 7, category: 'Mining', imageUrl: c7 },
  

    { id: 3, category: 'Irrigation', imageUrl: a2},
    { id: 4, category: 'Irrigation', imageUrl: a3 },
    { id: 5, category: 'Irrigation', imageUrl: a4 },
    { id: 6, category: 'Irrigation', imageUrl: a5 },
    { id: 7, category: 'Irrigation', imageUrl: a6 },
    { id: 8, category: 'Irrigation', imageUrl: a7 },
    { id: 9, category: 'Irrigation', imageUrl: a8 },
    { id: 10, category: 'Irrigation', imageUrl: b2 },
    { id: 11, category: 'Irrigation', imageUrl: b3 },
    { id: 12, category: 'Irrigation', imageUrl: b4 },
    { id: 13, category: 'Irrigation', imageUrl: b5 },
    { id: 14, category: 'Irrigation', imageUrl: b6 },
    { id: 15, category: 'Irrigation', imageUrl: b7 },
    { id: 16, category: 'Irrigation', imageUrl: b8 },




    { id: 2, category: 'Construction', imageUrl: d2 },
    { id: 3, category: 'Construction', imageUrl: d3 },
    { id: 4, category: 'Construction', imageUrl: d4 },
    { id: 5, category: 'Construction', imageUrl: d5 },
    { id: 6, category: 'Construction', imageUrl: d6 },
    { id: 7, category: 'Construction', imageUrl: d7 },
    { id: 8, category: 'Construction', imageUrl: d8 },
    { id: 9, category: 'Construction', imageUrl: d9 },
    { id: 10, category: 'Construction', imageUrl: d10 },
    { id: 11, category: 'Construction', imageUrl: d11 },
    { id: 12, category: 'Construction', imageUrl: d12 },
  ]
  );

  const filterItems = (category) => {
    if (category === 'all') {
      return galleryItems;
    } else {
      return galleryItems.filter((item) => item.category === category);
    }
  };

  const handleFilterChange = (category) => {
    setSelectedFilter(category);
  };

  return (
    <Box p={4}>
      <ButtonGroup mb={4}>
        <Button
          onClick={() => handleFilterChange('all')}
          isActive={selectedFilter === 'all'}
        >
          All
        </Button>
        <Button
          onClick={() => handleFilterChange('Mining')}
          isActive={selectedFilter === 'Mining'}
        >
          Mining
        </Button>
        <Button
          onClick={() => handleFilterChange('Irrigation')}
          isActive={selectedFilter === 'Irrigation'}
        >
          Irrigation
        </Button>
        <Button
          onClick={() => handleFilterChange('Construction')}
          isActive={selectedFilter === 'Construction'}
        >
          Construction
        </Button>
      </ButtonGroup>
      <Grid templateColumns="repeat(auto-fit, minmax(280px, 1fr))" gap={"9"}>
        {filterItems(selectedFilter).map((item) => (
          <GridItem key={item.id} width={"340px"} height={"200px"}  >
            <Image src={item.imageUrl} alt={item.category}  height={"100%"} width={"100%"}/>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default Gallery;
