import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,

  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
import C1 from "../Images/Infra/Picture1.jpg"
import { Link } from "react-router-dom";
import p1 from "../Images/Gallery/Package15/a3.JPG"
import { SettingsIcon } from "@chakra-ui/icons";

export default function CallToActionWithVideo() {
  return (
    <Container maxW={"7xl"}>
      <Stack
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
          >
            <br />
            <Text as={"span"} color={"red.400"}>
              Infrastructure
            </Text>
          </Heading>
          <Text color={"gray.500"} textAlign="left" fontSize={"lg"}>
            In Irrigation AMR India Limited is engaged in irrigation works
            including survey, investigation and design, excavation embankment,
            construction of related structures and concrete lining, formation of
            reservoirs, earthen Dams and construction of barrages etc. AMR India
            Limited leverages on the strengths gained in mining to execute the
            Irrigation projects. AMR India Limited is equipped with the
            requisite expertise and wide-ranging experience to undertake its
            projects on EPC, turnkey basis .These projects are executed using
            state-of-the-art design tools and project management techniques AMR
            India Limited has taken up projects related to river linking, dams,
            reservoirs, canals, etc.
          </Text>
        
        </Stack>
        <Flex
          flex={1}
          justify={"center"}
          align={"center"}
          position={"relative"}
          w={"full"}
        >
          <Blob
            w={"150%"}
            h={"150%"}
            position={"absolute"}
            top={"-20%"}
            left={0}
            zIndex={-1}
            color={useColorModeValue("red.50", "red.400")}
          />
          <Box
            position={"relative"}
            height={"300px"}
            rounded={"2xl"}
            boxShadow={"2xl"}
            width={"full"}
            overflow={"hidden"}
          >

            <Image
              alt={"Hero Image"}
              fit={"cover"}
              align={"center"}
              w={"100%"}
              h={"100%"}
              src={C1}
            />
          </Box>
        </Flex>
      </Stack>

      {/* ************************************************************************************************************************************************************************** */}
      <Box textAlign="center" py={0} px={6}>
        {/* <SettingsIcon boxSize={'50px'} color={'orange.300'} /> */}
        <Heading as="h2" size="xl" mt={"0px"} mb={2} color={"red.400"}>
          Our Successful Project
        </Heading>

      </Box>

      <Container maxW={'9xl'} p="9"  mt={["10px", "10px", "15px"]} >
        <Box

          marginTop={{ base: '2', sm: '5' }}
          display="flex"
          flexDirection={["column", "column", "row"]}
          justifyContent="space-between">
          <Box
            width={"100%"}
            mb={"30px"}
            display="flex"
            flex="1"
            marginRight="56px"
            position="relative"
            alignItems="center">
            <Box
              position={"relative"}
              top={"20px"}
              height={"300px"}
              rounded={"2xl"}
              boxShadow={"2xl"}
              width={"100%"}
              overflow={"hidden"}
            >

              <Image
                alt={"Hero Image"}
                fit={"cover"}
                align={"center"}
                w={"100%"}
                h={"100%"}
              src={p1 }
              />
            </Box>
            <Box zIndex="1" width="100%" position="absolute" height="100%"

            >

            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: '0', sm: '7' }}

          >


            <Text
              as="p"
              marginTop="0px"
              fontSize={"lg"}
              color={"gray.500"}
              textAlign={'justify'}>
              Civil Works for Embankment at Lower Reservoir for Pumped Storage Project (PSP) at Pinnapuram Village, Panyam Mandal, Kurnool, A.P, was undertaken for Greenko AP01 IREP Private Limited by Sri Siddharth Infratech & Services (I) Pvt Ltd. The contract value for the work was Rs. 181,58,12,500.00, and it commenced on 16.09.2022, with a planned completion date of 15.06.2023. By 31.03.2023, the total value of work executed reached Rs. 183,80,54,460.39. The scope of work involved various civil activities for the reservoir, including excavation, material stacking, supply of rockfill dam material, laying of materials on embankments, compaction, and D/s Riprap works. The project was an integral part of the 1200 MW Pumped Storage Project, which formed a crucial component of the Integrated Renewable Energy Project (IREP) Scheme in Pinnapuram, Kurnool District, Andhra Pradesh.
            </Text>
          </Box>

        </Box>
       
        <Divider marginTop="5" />







      </Container>

    </Container>
  );
}





export const Blob = (props) => {
  return (
    <Icon
      width={"100%"}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};










