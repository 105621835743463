import './App.css';
import AllRoutes from './AllRoutes/AllRoutes';
import Footer from './Pages/Footer';
import { Box } from '@chakra-ui/react';
import Navbar from "./Pages/Navbar"
function App() {
  return (
    <div className="App">
        <Navbar/>

        <AllRoutes/>
        <Box mt="100px" width={"100%"}>
        <Footer/>
        </Box>
        
    </div>
  );
}

export default App;
