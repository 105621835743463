import React, { useEffect } from 'react';
import {
  Box,
  Heading,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
  Button,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import MiningImg from '../Images/home/Mining.JPG'


const Mining = () => {
  
  return (
    <Container maxW={'7xl'} p="8"  mt={["10px","10px","15px"]}>
      <Box

        marginTop={{ base: '2', sm: '5' }}
        display="flex"
        flexDirection={["column","column","row"] }
        justifyContent="space-between">
        <Box
          width={"100%"}            
mb={"30px"}
          display="flex"
          flex="1"
          marginRight="56px"
          position="relative"
          alignItems="center">
            <Box
            position={"relative"}
            top={"20px"}
            height={"300px"}
            rounded={"2xl"}
            boxShadow={"2xl"}
            width={"100%"}
            overflow={"hidden"}
          >
           
            <Image
              alt={"Hero Image"}
              fit={"cover"}
              align={"center"}
              w={"100%"}
              h={"100%"}
              src={MiningImg }
            />
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%" 
                    
                    >
          
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '7' }}>
          <Heading marginTop="1" color={'red.400'}>
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
              Mining
            </Link>
          </Heading>
          <Text
            as="p"
            marginTop="5"
            fontSize={"lg"}
            color={"gray.500"}
            textAlign={'justify'}>
          The mining division of AMR INDIA LIMITED has its core operations in mineral rich belt of India. Right from planning, designing, execution and till mine closure in the most possible environmentally sustainable manner. 
           AMR India Limited holds a leading position in the Indian mining industry for its superior mining techniques, equipment, flexibility in operations and systems with zero tolerance in safety and sustainability.
           The company has capability of dealing with most of core minerals including Coal, Limestone, Lignite, Manganese, Iron ore & Bauxite.

          </Text>
        </Box>

      </Box>
     <Box>
      <Link to="/mining">
     <Button
      rounded={"full"}
      size={"lg"}
      fontWeight={"normal"}
      px={6}
      colorScheme={"red"}
      bg={"red.400"}
      _hover={{ bg: "red.500" }}
      ml={["-10%","30%","50%"]}
      mt={["40px"]}
     >Know More</Button>
</Link>
     </Box>
      <Divider marginTop="5" />
     
    </Container>
  );
};

export default Mining;