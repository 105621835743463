import React from 'react'
import {Box} from "@chakra-ui/react"
import Header from './Header'
import Aboutus from './About-us'
import Mining from './Mining'
import Services from './services'


const HomePage = () => {

  return (
    <Box  >
       
        <div data-aos="fade-left" >
         <Header  />
         </div>
<div  style={{marginTop:"10px",width:'100%'}}>
         <Aboutus />
         </div>

         <div data-aos="" style={{marginTop:"-30px"}}>
         <Mining />
         </div>

         <div data-aos="">
         <Services />
         </div>
        
    </Box>
  )
}

export default HomePage